<template>
  <div class="registration" :style="'height:'+winHeight+'px'">
    <RegistrationForm />
  </div>
</template>

<script>
// @ is an alias to /src
import RegistrationForm from '@/components/RegistrationForm.vue'
import { mapGetters } from 'vuex'

export default {
  name: 'Registration',
  components: {
    RegistrationForm
  },
  computed: {
      ...mapGetters(['winHeight'])
    },
}
</script>
