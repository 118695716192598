<template>
  <div class="results">
    <ResultsUserView />
  </div>
</template>

<script>
// @ is an alias to /src
import ResultsUserView from '@/components/ResultsUserView.vue'

export default {
  name: 'Results',
  components: {
    ResultsUserView
  }
}
</script>
