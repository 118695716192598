<template>
  <v-container fluid class="pt-0 px-0">
    <v-card tile :height="winHeight-50" color="rgba(0, 0, 0, 0.5)" class="" style="position:absolute;top:0px;overflow-y: scroll; overflow-x: hidden;width:100%;">
      <v-container fluid>
        <v-row>
          <v-col cols="8" :key="updated" >
            <v-row :style="'height: ' + winHeight*.465 + 'px'">
              <v-col cols="6" class="mr-n4">
                <v-list two-line class="mx-2 mt-4 mb-2 fill-height" rounded color="rgba(0, 0, 0, 0.75)" v-for="cat in ['BestCostume']" :key="cat">
                  <p class="text-h3 text-center mb-2" :key="cat" style="font-family: Italiana, sans-serif !important; font-weight: bold; color: #FDD835;">
                    {{catMap[cat]}} <v-icon x-large>{{trophyMap[cat]}}</v-icon>
                  </p>
                  <v-divider></v-divider>
                  <template v-for="(score, index) in standings[cat]">
                    <v-divider
                      v-if="index % 4"
                      :key="index"
                    ></v-divider>

                    <v-list-item
                      dark
                      :key="score.email"
                    >
                      <v-list-item-avatar tile :width="index==0 ? 75 : 50" :height="index==0 ? 75 : 50" >
                        <v-img :src="score.selfieURL"></v-img>
                      </v-list-item-avatar>

                      <v-list-item-content>
                        <v-list-item-title :class="index==0 ? 'text-h5':''">{{score.name}}</v-list-item-title>
                        <v-list-item-subtitle :class="index==0 ? 'text-h6':''">{{score.celebName}}</v-list-item-subtitle>
                      </v-list-item-content>
                      <v-list-item-action>
                        <v-chip
                  class="ma-2"
                  :color="index==0 ? 'yellow darken-1' : 'white darken-2'"
                  text-color="black"
                  :large="index==0"
                >
                  {{score.score}} 
                  <v-icon :large="index==0" :color="index==0 ? 'white darken-2' : 'yellow darken-1'" :style="'transform: scaleX(' + (flipped ? -1 : 1) + ')'" right v-if="index==0">
                    mdi-candelabra-fire
                  </v-icon>
                </v-chip>
                      </v-list-item-action>
                    </v-list-item>
                  </template>
                </v-list>
              </v-col>
              <v-col cols="6">
                <v-list two-line class="mx-2 mt-4 mb-2 fill-height" rounded color="rgba(0, 0, 0, 0.75)" v-for="cat in ['ScariestCostume']" :key="cat">
                  <p class="text-h3 text-center mb-2" :key="cat" style="font-family: Italiana, sans-serif !important; font-weight: bold; color: #FDD835;">
                    {{catMap[cat]}} <v-icon x-large>{{trophyMap[cat]}}</v-icon>
                  </p>
                  <v-divider></v-divider>
                  <template v-for="(score, index) in standings[cat]">
                    <v-divider
                      v-if="index % 4"
                      :key="index"
                    ></v-divider>

                    <v-list-item
                      dark
                      :key="score.email"
                    >
                      <v-list-item-avatar tile :width="index==0 ? 75 : 50" :height="index==0 ? 75 : 50" >
                        <v-img :src="score.selfieURL"></v-img>
                      </v-list-item-avatar>

                      <v-list-item-content>
                        <v-list-item-title :class="index==0 ? 'text-h5':''">{{score.name}}</v-list-item-title>
                        <v-list-item-subtitle :class="index==0 ? 'text-h6':''">{{score.celebName}}</v-list-item-subtitle>
                      </v-list-item-content>
                      <v-list-item-action>
                        <v-chip
                  class="ma-2"
                  :color="index==0 ? 'yellow darken-1' : 'white darken-2'"
                  text-color="black"
                  :large="index==0"
                >
                  {{score.score}} 
                  <v-icon :large="index==0" :color="index==0 ? 'white darken-2' : 'yellow darken-1'" :style="'transform: scaleX(' + (flipped ? -1 : 1) + ')'" right v-if="index==0">
                    mdi-candelabra-fire
                  </v-icon>
                </v-chip>
                      </v-list-item-action>
                    </v-list-item>
                  </template>
                </v-list>
              </v-col>
            </v-row>
            <v-row :style="'height: ' + winHeight*.465 + 'px'">
              <v-col cols="6" class="mr-n4">
                <v-list two-line class="mx-2 mt-4 mb-2 fill-height" rounded color="rgba(0, 0, 0, 0.75)" v-for="cat in ['FunniestCostume']" :key="cat">
                  <p class="text-h3 text-center mb-2" :key="cat" style="font-family: Italiana, sans-serif !important; font-weight: bold; color: #FDD835;">
                    {{catMap[cat]}} <v-icon x-large>{{trophyMap[cat]}}</v-icon>
                  </p>
                  <v-divider></v-divider>
                  <template v-for="(score, index) in standings[cat]">
                    <v-divider
                      v-if="index % 4"
                      :key="index"
                    ></v-divider>

                    <v-list-item
                      dark
                      :key="score.email"
                    >
                      <v-list-item-avatar tile :width="index==0 ? 75 : 50" :height="index==0 ? 75 : 50" >
                        <v-img :src="score.selfieURL"></v-img>
                      </v-list-item-avatar>

                      <v-list-item-content>
                        <v-list-item-title :class="index==0 ? 'text-h5':''">{{score.name}}</v-list-item-title>
                        <v-list-item-subtitle :class="index==0 ? 'text-h6':''">{{score.celebName}}</v-list-item-subtitle>
                      </v-list-item-content>
                      <v-list-item-action>
                        <v-chip
                  class="ma-2"
                  :color="index==0 ? 'yellow darken-1' : 'white darken-2'"
                  text-color="black"
                  :large="index==0"
                >
                  {{score.score}} 
                  <v-icon :large="index==0" :color="index==0 ? 'white darken-2' : 'yellow darken-1'" :style="'transform: scaleX(' + (flipped ? -1 : 1) + ')'" right v-if="index==0">
                    mdi-candelabra-fire
                  </v-icon>
                </v-chip>
                      </v-list-item-action>
                    </v-list-item>
                  </template>
                </v-list>
              </v-col>
              <v-col cols="6">
                <v-list two-line class="mx-2 mt-4 mb-2 fill-height" rounded color="rgba(0, 0, 0, 0.75)" v-for="cat in ['MostOriginal']" :key="cat">
                  <p class="text-h3 text-center mb-2" :key="cat" style="font-family: Italiana, sans-serif !important; font-weight: bold; color: #FDD835;">
                    {{catMap[cat]}} <v-icon x-large>{{trophyMap[cat]}}</v-icon>
                  </p>
                  <v-divider></v-divider>
                  <template v-for="(score, index) in standings[cat]">
                    <v-divider
                      v-if="index % 4"
                      :key="index"
                    ></v-divider>

                    <v-list-item
                      dark
                      :key="score.email"
                    >
                      <v-list-item-avatar tile :width="index==0 ? 75 : 50" :height="index==0 ? 75 : 50" >
                        <v-img :src="score.selfieURL"></v-img>
                      </v-list-item-avatar>

                      <v-list-item-content>
                        <v-list-item-title :class="index==0 ? 'text-h5':''">{{score.name}}</v-list-item-title>
                        <v-list-item-subtitle :class="index==0 ? 'text-h6':''">{{score.celebName}}</v-list-item-subtitle>
                      </v-list-item-content>
                      <v-list-item-action>
                        <v-chip
                  class="ma-2"
                  :color="index==0 ? 'yellow darken-1' : 'white darken-2'"
                  text-color="black"
                  :large="index==0"
                >
                  {{score.score}} 
                  <v-icon :large="index==0" :color="index==0 ? 'white darken-2' : 'yellow darken-1'" :style="'transform: scaleX(' + (flipped ? -1 : 1) + ')'" right v-if="index==0">
                    mdi-candelabra-fire
                  </v-icon>
                </v-chip>
                      </v-list-item-action>
                    </v-list-item>
                  </template>
                </v-list>
              </v-col>
            </v-row>
          </v-col>
          <v-col cols="4" class="pl-0">
            <v-row class="mt-4 pr-5 mb-6">
              <v-card style="width:100%;" color="rgba(0, 0, 0, 0.75)" class="" >
                <v-card-text class="pt-7">
                  <v-row>
                    <v-col class="text-center">
                      <p class="text-h3 mb-n2" style="font-family: Italiana, sans-serif !important; font-weight: bold; color: #FDD835;">Time Left to Vote</p>
                    </v-col>
                  </v-row>
                  <v-row>
                    <TimerItem v-for="time in times" :time="time" :key="time.id"></TimerItem>
                  </v-row>
                </v-card-text>
              </v-card>
            </v-row>
            <v-row class="mt-4 pr-5">
              <v-card :key="updated" style="width:100%;" color="rgba(0, 0, 0, 0.75)" class="" >
                <v-card-text class="pt-7 pb-6">
                  <v-row>
                    <v-col class="text-center">
                      <p class="text-h3 mb-n2" style="font-family: Italiana, sans-serif !important; font-weight: bold; color: #FDD835;">Most Votes Received</p>
                    </v-col>
                  </v-row>
                  <v-row>
                    <v-list two-line class="mx-2 mt-0 mb-0 fill-height" style="width:100%;" rounded color="rgba(0, 0, 0, 0)">
                        <v-list-item
                          dark
                        >
                          <v-list-item-avatar tile width="75" height="75" >
                            <v-img :src="topVoteGetter.selfieURL"></v-img>
                          </v-list-item-avatar>

                          <v-list-item-content>
                            <v-list-item-title class="text-h5">{{topVoteGetter.name}}</v-list-item-title>
                            <v-list-item-subtitle class="text-h6">{{topVoteGetter.celebName}}</v-list-item-subtitle>
                          </v-list-item-content>
                          <v-list-item-action>
                            <v-chip
                              class="ma-2"
                              color="yellow darken-1"
                              text-color="black"
                              large
                            >
                              {{topVoteGetter.totalScore}} 
                              <v-icon large color="white darken-2" :style="'transform: scaleX(' + (flipped ? -1 : 1) + ')'" right>
                                mdi-candelabra-fire
                              </v-icon>
                            </v-chip>
                          </v-list-item-action>
                        </v-list-item>
                    </v-list>
                  </v-row>
                </v-card-text>
              </v-card>
            </v-row>
            <v-row class="mt-9" :style="'width:100%; max-height: ' + winHeight*.45 + 'px'">
              <v-card style="width:100%;" color="rgba(0, 0, 0, 0.75)" class="" >
                <v-card-text class="">
                  <v-row>
                    <v-col class="text-center">
                      <p class="text-h3 mt-n1 mb-2" style="font-family: Italiana, sans-serif !important; font-weight: bold; color: #FDD835;">Dead Celebrity Trivia</p>
                    </v-col>
                  </v-row>
                  <v-row>
                    <v-carousel hide-delimiters :show-arrows="false" cycle interval="60000" :height="winHeight*.37" @change="imageChanged">
                      <v-carousel-item
                        v-for="(item,i) in trivia"
                        :key="i"
                        :src="'triviaImages/' + item.imgURL"
                      >
                      <v-row class="text-right pa-0 ma-0">
                        <v-scroll-x-reverse-transition>
                        <v-card tile style="width:100%;bottom:0;position:absolute;" color="rgba(0, 0, 0, 0.95)" class="" >
                          <v-card-text>
                            <p class="text-h5 px-12 py-0 my-0" style="color: white;">
                              {{item.question}}
                            </p>
                            <p v-if="!displayAnswer" class="text-h4 px-12 py-0 my-0" style="opacity:0;">
                              {{item.answer}}
                            </p>
                            <v-scroll-x-reverse-transition>
                              <p v-if="displayAnswer" class="text-h4 px-12 py-0 my-0" style="color: #FDD835;">
                                {{item.answer}}
                              </p>
                            </v-scroll-x-reverse-transition>
                          </v-card-text>
                        </v-card>
                      </v-scroll-x-reverse-transition>
                      </v-row>
                    </v-carousel-item>
                    </v-carousel>
                  </v-row>
                </v-card-text>
              </v-card>
            </v-row>
          </v-col>
        </v-row>
      </v-container>
    </v-card>
  </v-container>
</template>

<script>
  import { mapGetters } from 'vuex'
  import { mapState } from 'vuex'
  import TimerItem from '@/components/TimerItem.vue'
  import triviaFile from '@/assets/celebFacts.json'

  export default {
    name: 'ResultsUserView',
    components:{
      TimerItem
    },
    data: () => ({
      flipped: false,
      cats:[],
      standings:{},
      totalVotes:{},
      topVoteGetter:{},
      trivia: triviaFile,
      displayAnswer: false,
      updated:0,
      startTime: "July 7, 2017 12:03:00",
      endTime: "October 27, 2022 19:00:00",
      times: [
        //{ id: 0, text: "Days", time: 1 },
        { id: 0, text: "Hours", time: 1 },
        { id: 1, text: "Minutes", time: 1 },
        { id: 2, text: "Seconds", time: 1 }
      ],
      progress: 100,
      timeinterval: undefined
    }),
    computed: {
      ...mapGetters(['winHeight','voterid','office','participantList','categories','catMap','trophyMap','participants']),
      ...mapState([]),
    },
    watch: {
      participants: {
        handler(newValue, oldValue) {
          console.log("PARTICIPANTS UPDATED");
          this.init();
        },
        deep: true,
      }
    },
    created(){
      this.updateTimer();
      this.timeinterval = setInterval(this.updateTimer, 1000);
      this.unsubscribe = this.$store.subscribe((mutation, state) => {
        if (mutation.type === 'SOCKET_ONMESSAGE') {
          console.log("PARTICIPANTS UPDATED");
          this.init();
        }
      })
    },
    beforeDestroy() {
      this.unsubscribe();
    },
    beforeMount(){
      let vm = this;
      setInterval(() => vm.flipped = !this.flipped,10000);
    },
    mounted() {
      this.valid = false;
      this.init();
    },
    methods: {
      imageChanged(){
        this.displayAnswer = false;
        let vm = this;
        setTimeout(() => vm.displayAnswer=true,50000)
      },
      init(){
        this.totalVotes = {};
        this.topVoteGetter = {};
        let cats = Object.keys(this.catMap);
        this.cats = cats;
        for (let c=0; c<cats.length; c++){
          this.standings[cats[c]] = [];
          for (let p=0; p<this.participants.length; p++){
            if(this.participants[p][cats[c]]>0){
              this.standings[cats[c]].push({
                email: this.participants[p].email,
                score: this.participants[p][cats[c]],
                celebName: this.participants[p].celebName,
                name: this.participants[p].name,
                selfieURL: this.participants[p].selfieURL
              });
              if(!this.totalVotes[this.participants[p].email]){
                this.totalVotes[this.participants[p].email] = 0;
              }
              this.totalVotes[this.participants[p].email] += this.participants[p][cats[c]];
            }
          }
          this.standings[cats[c]].sort((a,b) => b.score - a.score);
          if(this.standings[cats[c]].length > 4){
            this.standings[cats[c]].length = 4;
          }
        }
        const tvg = Object.entries(this.totalVotes).sort(([,a],[,b]) => b-a)[0];
        if(tvg){
          this.topVoteGetter = this.participants.filter(obj => {return obj.email === tvg[0]})[0];
          this.topVoteGetter.totalScore = tvg[1];
        }

        this.updated++;
      },
      updateTimer: function() {
        if (
          this.times[2].time > 0 ||
          this.times[1].time > 0 ||
          this.times[0].time > 0
        ) {
          this.getTimeRemaining();
          this.updateProgressBar();
        } else {
          clearTimeout(this.timeinterval);
          // this.times[3].time = this.times[2].time = this.times[1].time = this.times[0].time = 0;
          this.progress = 0;
        }
      },
      getTimeRemaining: function() {
        let t = Date.parse(new Date(this.endTime)) - Date.parse(new Date());
      if(t >= 0){
        this.times[2].time = Math.floor(t / 1000 % 60); //seconds
        this.times[1].time = Math.floor(t / 1000 / 60 % 60); //minutes
        this.times[0].time = Math.floor(t / (1000 * 60 * 60) % 24); //hours
       //this.times[0].time = Math.floor(t / (1000 * 60 * 60 * 24)); //days
        }else {
           this.times[2].time = this.times[1].time = this.times[0].time = 0;
          this.progress = 0;
        }
      },
      updateProgressBar: function() {
        let startTime = Date.parse(new Date(this.startTime));
        let currentTime = Date.parse(new Date());
        let endTime = Date.parse(new Date(this.endTime));
        let interval = parseFloat(
          (currentTime - startTime) / (endTime - startTime) * 100
        ).toFixed(2);
        this.progress = 100-interval;
      }
    }
  }
</script>

<style>
.v-carousel__item .v-image__image{
  /* filter: blur(3px); */
}
</style>