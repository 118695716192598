<template>
  <div class="results">
    <ResultsScreenView />
  </div>
</template>

<script>
// @ is an alias to /src
import ResultsScreenView from '@/components/ResultsScreenView.vue'

export default {
  name: 'Results',
  components: {
    ResultsScreenView
  }
}
</script>
