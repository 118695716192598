<template>
    <v-col class="text-center">
        <p class="text-h2 mb-0" style="font-family: Italiana, sans-serif !important; font-weight: bold; color: white;">{{time.time}}</p>
        <p class="text-h4" style="font-family: Italiana, sans-serif !important; font-weight: bold; color: white;">{{time.text}}</p>
    </v-col>
</template>

<script>

export default {
  name: 'Home',
  props: ["time"],
}
</script>