<template>
  <v-container fluid fill-height class="pt-0 px-0" >
    <!-- <v-row class="text-center">
      <p class="text-h3 ma-4" style="width:100%;font-family: Italiana, sans-serif !important; font-weight: bold; color: #FDD835;">Vote!</p>
    </v-row> -->

    <v-row>
      <v-container fluid fill-height>
        <v-data-iterator
          :items="participants"
          :search="search"
          hide-default-footer
          style="width:100%"
          :loading="loading"
          items-per-page="100"
        >
          <template v-slot:header>
            <v-toolbar
              dark
              color="black"
              class=" mt-0"
              width="100%"
              style="position: fixed; z-index: 999;"
            >
              <v-text-field
                v-model="search"
                clearable
                flat
                full-width
                dense
                solo-inverted
                hide-details
                prepend-inner-icon="mdi-magnify"
                label="Search"
              ></v-text-field>
            </v-toolbar>
          </template>

          <template v-slot:default="props" >
            <v-card tile color="rgba(0, 0, 0, 0.5)" class="py-4" height="100%" style="overflow-y: scroll; overflow-x: hidden;width:100%;margin-top:56px">
              <v-row>
                <v-col
                  v-for="(item, i) in props.items"
                  :key="item.email"
                  cols="12"
                  sm="6"
                  md="4"
                  lg="3"
                >
                <v-card
                  class="mx-auto"
                  max-width="344"
                  color="rgba(0, 0, 0, 0.75)"
                >
                  <v-img
                    :src="item.selfieURL"
                    height="200px"
                    position="center -50px"
                  ></v-img>

                  <v-card-title>
                    {{ item.name }}
                  </v-card-title>

                  <v-card-subtitle class="pb-0">
                    {{item.celebName}}
                  </v-card-subtitle>

                  <v-card-actions class="pt-2">
                    <v-row no-gutters v-if="!votedFor[i]">
                      <v-btn
                        color="yellow darken-1"
                        text
                        @click="showMe(i)"
                        :disabled="votedFor[i] || disableAll"
                      >
                        Vote for me!
                      </v-btn>

                      <v-spacer></v-spacer>

                      <v-btn
                        icon
                        @click="showMe(i)"
                        :disabled="votedFor[i] || disableAll"
                      >
                        <v-icon>{{ show[i] ? 'mdi-chevron-up' : 'mdi-chevron-down' }}</v-icon>
                      </v-btn>
                    </v-row>
                    <v-row no-gutters v-else>
                      <p class="text-subtitle ma-auto yellow--text text--darken-1" style="font-weight: bold;">
                        <v-icon
                          right
                          dark
                          small
                          color="yellow darken-1"
                          class="mb-1"
                        >
                         {{trophyMap[votedFor[i]]}}
                        </v-icon>
                        You voted: {{catMap[votedFor[i]]}}
                        <v-icon
                          left
                          dark
                          small
                          color="yellow darken-1"
                          class="mb-1"
                        >
                          {{trophyMap[votedFor[i]]}}
                        </v-icon>
                      </p>
                    </v-row>
                  </v-card-actions>

                  <v-expand-transition>
                    <div v-show="show[i]" :key="updated">
                      <v-divider></v-divider>

                      <v-card-text>
                        <v-select
                          :items="categories"
                          item-value="value"
                          label="Select Category"
                          v-model="itemCat[i]"
                          outlined
                          dense
                          :disabled="submittingVote"
                          menu-props="top"
                        >
                          <template #item="{ item }">
                            <v-icon color="yellow darken-1"> {{ item.icon }} </v-icon> &nbsp; {{item.text}} 
                          </template>
                          <template #selection="{ item }">
                            <v-icon color="yellow darken-1"> {{ item.icon }} </v-icon> &nbsp; <span style="font-family: Italiana, sans-serif !important; font-weight: bold; color: #FDD835;">{{item.text}} </span>
                          </template>
                        </v-select>
                        <v-btn
                          color="success"
                          class="mr-4"
                          @click="submitVote(i)"
                          block
                          :loading="submittingVote"
                        >
                          Submit Vote
                        </v-btn>
                      </v-card-text>
                    </div>
                  </v-expand-transition>
                </v-card>
                </v-col>
              </v-row>
            </v-card>
          </template>
        </v-data-iterator>
      </v-container>
    </v-row>
  </v-container>
</template>

<script>
  import { mapGetters } from 'vuex'
  export default {
    name: 'VoteForm',
    data: () => ({
      valid: false,
      loading:true,
      search: '',
      disableAll:false,
      submittingVote: false,
      show: [],
      itemCat:[],
      votedFor:[],
      updated: 0,
      //selectableCats: [],
    }),
    computed: {
      ...mapGetters(['winHeight','voterid','categories','catMap','trophyMap','participants','voterInfo'])
    },
    watch: {
      voterInfo(){
        console.log("VOTER UPDATED");
        //this.init();
      },
      participants(){
        console.log("PARTICIPANTS UPDATED");
        this.init();
      }
    },
    mounted() {
      console.log("MOUNTED");
      this.valid = false;
      this.loading = true;
      this.init();
    },
    methods: {
      init(){
        this.show = [];
        this.itemCat = [];
        this.votedFor = [];
        //this.selectableCats = this.categories;
        this.disableAll = this.voterInfo.votedfor && this.voterInfo.votedfor.length==4;
        for (let p=0; p<this.participants.length; p++){
          this.show.push(false);
          let found = false;
          if(this.voterInfo && this.voterInfo.votedfor){
            for (let v=0; v<this.voterInfo.votedfor.length; v++){
              if(this.voterInfo.votedfor[v].participant == this.participants[p].email){
                this.itemCat.push(this.voterInfo.votedfor[v].category);
                this.votedFor.push(this.voterInfo.votedfor[v].category);
                found = true;
                break;
              }
            }
          }
          if(!found){
            this.itemCat.push("");
            this.votedFor.push(false);
          }
        }
        this.loading = false;
        this.updated++;
      },
      showMe(i){
        console.log("SHOW: " + i + " = " + this.show[i]);
        this.show[i] = !this.show[i];
        console.log("SHOW: " + i + " = " + this.show[i]);
        this.updated++;
      },
      async submitVote(i){
        this.submittingVote = true;
        console.log(JSON.stringify(this.participants[i]));
        console.log(this.itemCat[i]);
        console.log(this.voterid);

        const body = {
          "voterid": this.voterid,
          "category": this.itemCat[i]
        }
        console.log(body);
        const sendVoteRes = await this.sendVote(this.participants[i].email,JSON.stringify(body));
        console.log(sendVoteRes);
        this.$store.commit('updateVotedFor', {
          "category":this.itemCat[i],
          "participant":this.participants[i].email
        });

        //this.selectableCats = this.selectableCats.filter(e => e !== this.itemCat[i]);
        this.$store.commit('updateCategories', this.categories.filter(e => e.value !== this.itemCat[i]));
        this.votedFor[i] = this.itemCat[i];
        this.disableAll = this.votedFor.length==4;
        this.itemCat = [];
        this.show[i] = false;
        this.submittingVote = false;
        this.updated++;
      },
      async sendVote(email,body){
        const url = "https://7ibv9h4nu3.execute-api.us-east-1.amazonaws.com/v1/vote/d6aa22/" + email;
        const settings = {
                  method: "POST",
                  body: body,
                  headers: {
                    "Content-type": "application/json"
                  }
                };
          try {
            const fetchResponse = await fetch(url,settings);
            const data = await fetchResponse.json();
            return data;
          } catch (e) {
            return e;
          }
      },
    }
  }
</script>

<style>
.v-card {
  display: flex !important;
  flex-direction: column;
}

.v-card__text {
  flex-grow: 1;
  overflow: auto;
}
</style>