<template>
  <v-container fluid class="pt-0 px-0">
    <v-card tile :height="winHeight-104" :key="updated" color="rgba(0, 0, 0, 0.5)" class="" style="position:absolute;top:0px;overflow-y: scroll; overflow-x: hidden;width:100%;">
      <v-container  style="max-width:800px;" class="mx-auto my-0 pa-0">
        <v-list two-line class="mx-2 mt-4 mb-2"  color="rgba(0, 0, 0, 0.75)" v-for="cat in cats" :key="cat">
          <p class="text-h5 text-center mb-2" :key="cat" style="font-family: Italiana, sans-serif !important; font-weight: bold; color: #FDD835;">
            {{catMap[cat]}} <v-icon>{{trophyMap[cat]}}</v-icon>
          </p>
          <v-divider></v-divider>
          <template v-for="(score, index) in standings[cat]">
            <v-divider
              v-if="index % 3"
              :key="index"
            ></v-divider>

            <v-list-item
              dark
              :key="score.email"
            >
              <v-list-item-avatar tile :width="index==0 ? 75 : 50" :height="index==0 ? 75 : 50">
                <v-img :src="score.selfieURL"></v-img>
              </v-list-item-avatar>

              <v-list-item-content>
                <v-list-item-title :class="index==0 ? 'text-h5':''">{{score.name}}</v-list-item-title>
                <v-list-item-subtitle :class="index==0 ? 'text-h6':''">{{score.celebName}}</v-list-item-subtitle>
              </v-list-item-content>
              <v-list-item-action>
                <v-chip
                  class="ma-2"
                  :color="index==0 ? 'yellow darken-1' : 'white darken-2'"
                  text-color="black"
                  :large="index==0"
                >
                  {{score.score}} 
                  <v-icon :large="index==0" :color="index==0 ? 'white darken-2' : 'yellow darken-1'" :style="'transform: scaleX(' + (flipped ? -1 : 1) + ')'" right v-if="index==0">
                    mdi-candelabra-fire
                  </v-icon>
                </v-chip>
              </v-list-item-action>
            </v-list-item>
          </template>
        </v-list>
      </v-container>
    </v-card>
  </v-container>
</template>

<script>
  import { mapGetters } from 'vuex'
  import { mapState } from 'vuex';
  export default {
    name: 'ResultsUserView',
    data: () => ({
      flipped: false,
      cats:[],
      standings:{},
      updated:0,
    }),
    computed: {
      ...mapGetters(['winHeight','voterid','office','participantList','categories','catMap','trophyMap','participants']),
      ...mapState([]),
    },
    watch: {
      participants: {
        handler(newValue, oldValue) {
          console.log("PARTICIPANTS UPDATED");
          this.init();
        },
        deep: true,
      }
    },
    created(){
      this.unsubscribe = this.$store.subscribe((mutation, state) => {
        if (mutation.type === 'SOCKET_ONMESSAGE') {
          console.log("PARTICIPANTS UPDATED");
          this.init();
        }
      })
    },
    beforeDestroy() {
      this.unsubscribe();
    },
    beforeMount(){
      let vm = this;
      setInterval(() => vm.flipped = !this.flipped,10000);
    },
    mounted() {
      this.valid = false;
      this.init();
    },
    methods: {
      init(){
        let cats = Object.keys(this.catMap);
        this.cats = cats;
        for (let c=0; c<cats.length; c++){
          this.standings[cats[c]] = [];
          for (let p=0; p<this.participants.length; p++){
            if(this.participants[p][cats[c]]>0){
              this.standings[cats[c]].push({
                email: this.participants[p].email,
                score: this.participants[p][cats[c]],
                celebName: this.participants[p].celebName,
                name: this.participants[p].name,
                selfieURL: this.participants[p].selfieURL
              });
              //this.standings[cats[c]].push({ divider: true, inset: true });
            }
          }
          this.standings[cats[c]].sort((a,b) => b.score - a.score);
          if(this.standings[cats[c]].length > 3){
            this.standings[cats[c]].length = 3;
          }
        }
        //this.updated++;
      },
    }
  }
</script>
