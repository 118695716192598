<template>
  <v-container fluid>
    <!-- <v-row class="text-center">
      <p class="text-h3 ma-4" style="width:100%;font-family: Italiana, sans-serif !important; font-weight: bold; color: #FDD835;">Register!</p>
    </v-row> -->
    <v-card tile :height="winHeight-104" color="rgba(0, 0, 0, 0.5)" class="py-4 ma-0" style="position:absolute;top:0; left:0; overflow-y: scroll; overflow-x: hidden;width:100%;">
      <v-row>
        <v-col class="mx-auto" lg="3" cols="8">
          <v-img aspect-ratio="1" :src="imageSrc" class="" ></v-img>
        </v-col>
      </v-row>
      <v-row>
        <v-col class="" lg="6" offset-lg="3" cols="10" offset="1">
          <v-form
            ref="form"
            v-model="valid"
          >
            <v-file-input
              :rules="imageRules"
              accept="image/*"
              prepend-icon="mdi-camera"
              label="Upload a Costume Selfie"
              @change="selectFile"
              outlined
              required
              :disabled="participantCreated || addingParticipant"
            ></v-file-input>

            <v-text-field
              v-model="celName"
              :rules="celNameRules"
              label="Celebrity Name"
              required
              outlined
              :disabled="participantCreated || addingParticipant"
            ></v-text-field>
            
            <v-text-field
              v-model="name"
              :rules="nameRules"
              label="Name"
              required
              outlined
              :disabled="participantCreated || addingParticipant"
            ></v-text-field>

            <v-text-field
              v-model="email"
              :rules="[alreadyParticipating]"
              label="E-mail"
              suffix="@def6.com"
              required
              outlined
              :disabled="participantCreated || addingParticipant"
            ></v-text-field>

            <v-btn
              :disabled="!valid || addingParticipant || participantCreated"
              color="success"
              class="mr-4"
              @click="createParticipant"
              block
              :loading="addingParticipant"
            >
              Submit
            </v-btn>
          </v-form>
          <v-overlay
            absolute
            dark
            opacity=".75"
            color="black"
            :value="participantCreated"
          >
            <v-container class="text-center ma-auto pa-6">
              <p class="text-h4" style="font-family: Italiana, sans-serif !important; font-weight: bold; color: #FDD835;">
                Thanks for entering {{name}}
                </p>
              <p class="text-h6 mb-12">
                Good luck...now go vote for yourself like the real celebrity you are!
              </p>
              <v-btn
                to="vote"
                x-large
                color="yellow darken-2"
                class="pa-10 my-12"
                style="font-family: Italiana, sans-serif !important; font-size: 1.5em; font-weight: bold; color:black;"
              >
                <v-icon
                  left
                  dark
                  large
                >
                  mdi-knife-military
                </v-icon>
                &nbsp;
                Go Vote!
              </v-btn>
            </v-container>
          </v-overlay>
        </v-col>
      </v-row>
    </v-card>
  </v-container>
</template>

<script>
  import { mapGetters } from 'vuex'
  export default {
    name: 'RegistrationForm',
    data: () => ({
      imageSrc: "https://via.placeholder.com/500/000000/FFFFFF/?text=Costume Selfie Preview",
      valid: false,
      imageFile: null,
      participantCreated: false,
      addingParticipant: false,
      imageRules: [
        v => !!v || 'Image is required',
        v => (v && v.size > 0) || 'Image is required',
        v => (v && v.size < 10485760) || 'Image must be under 10MB',
      ],
      celName: '',
      celNameRules: [
        v => !!v || 'Celebrity name is required',
        v => (v && v.length > 3) || 'Celebrity name must be more than 3 characters',
      ],
      name: '',
      nameRules: [
        v => !!v || 'Name is required',
        v => (v && v.length > 3) || 'Name must be more than 3 characters',
      ],
      email: '',
      emailRules: [
        v => !!v || 'E-mail is required',
      ],
    }),
    computed: {
      ...mapGetters(['winHeight','voterid','office','participantList','participants','categories','catMap'])
    },
    watch: {
      imageFile(newI) {
        if(newI){
          this.imageSrc= URL.createObjectURL(newI);
        }else{
          this.imageSrc = "https://via.placeholder.com/500/000000/FFFFFF/?text=Costume Selfie Preview";
        }
      },
      participants(){
        console.log("PARTICIPANTS UPDATED");
        this.init();
      }
    },
    mounted() {
      this.valid = false;
      this.init();
    },
    methods: {
      init(){
        for (let p=0; p<this.participants.length; p++){
          if(this.participants[p].voterid == this.voterid){
            this.participantCreated = true;
            break;
          }
        }
      },
      alreadyParticipating(v){
        const email = v + "@def6.com";
        return !this.participantList.includes(email) ? true : 'You can only register once!';
      },
      async createParticipant () {
        this.addingParticipant = true;
        const newName = this.email + "_" + this.imageFile.name;
        Object.defineProperty(this.imageFile, 'name', {
          writable: true,
          value: newName
        });
        const fileUpRes = await this.fileUpload();
        console.log(fileUpRes);

        const body = {
          "voterid":this.voterid,
          "office":this.office,
          "email": this.email + "@def6.com",
          "celebName": this.celName,
          "name": this.name,
          "selfieURL": "https://voter-participant-images.s3.amazonaws.com/" + this.imageFile.name
        }
        let catShorts = Object.keys(this.catMap);
        for (let c=0; c<catShorts.length; c++){
          body[catShorts[c]] = 0;
        }

        const partRes = await this.postParticipant(JSON.stringify(body));
        this.$store.commit('pushParticipant', body);
        console.log(partRes);
        this.participantCreated = true;
        this.addingParticipant = false;
      },
      selectFile(e){
        console.log(e);
        this.imageFile = e;
      },
      async fileUpload(){
        const settings = {
                method: "PUT",
                body: this.imageFile,
                redirect: "follow",
                headers: {
                  "Content-type": this.imageFile.type
                }
              };
        try {
          const fetchResponse = await fetch("https://2eyfq2rp4k.execute-api.us-east-1.amazonaws.com/v1/voter-participant-images/" + this.imageFile.name,settings);
          const data = await fetchResponse.text();
          return data;
        } catch (e) {
          return e;
        }
      },
      async postParticipant(body){
        const settings = {
                method: "POST",
                body: body,
                headers: {
                  "Content-type": "application/json"
                }
              };
        try {
          const fetchResponse = await fetch("https://7ibv9h4nu3.execute-api.us-east-1.amazonaws.com/v1/createParticipant/d6aa22",settings);
          const data = await fetchResponse.json();
          return data;
        } catch (e) {
          return e;
        }
      },
    }
  }
</script>
