<template>
  <div class="registration">
    <VoteForm />
  </div>
</template>

<script>
// @ is an alias to /src
import VoteForm from '@/components/VoteForm.vue'

export default {
  name: 'Vote',
  components: {
    VoteForm
  }
}
</script>
